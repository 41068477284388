import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { RESEND_MAIL } from "@/core/services/store/auth.module";
export default {
  data: function data() {
    var email = this.$route.query.email;
    return {
      email: email,
      loading: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["currentUser", "isAuthenticated", "layoutConfig"])), {}, {
    loaderLogo: function loaderLogo() {
      return this.layoutConfig("loader.logo");
    }
  }),
  methods: {
    resend: function resend(e) {
      var _this = this;

      e.preventDefault();

      if (this.email) {
        this.loading = true;
        this.$store.dispatch(RESEND_MAIL, {
          email: this.email
        }).then(function (_ref) {
          var code = _ref.code,
              message = _ref.message;
          _this.loading = false;

          if (code === 200) {
            _this.$message.success(message);
          } else {
            _this.$message.error(message);
          }
        }, function (error) {
          _this.loading = false;

          _this.$message.error(error.message);
        });
      }
    }
  }
};